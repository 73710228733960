import { Vehicle } from '@/api/vehicle';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useApiStore = defineStore('api', ApiStore);

function ApiStore() {
    const vehicles = ref<Vehicle[]>([]);
    const inventory = ref([]);

    function getVehicle(id: number): Vehicle | undefined {
        return vehicles.value.find((vehicle) => vehicle.id == id);
    }

    function setVehicle(vehicle: Vehicle): void {
        const index = vehicles.value.findIndex((v) => v.id == vehicle.id);

        if (index > -1) {
            vehicles.value[index] = vehicle;
        } else {
            vehicles.value.push(vehicle);
        }
    }

    return { vehicles, inventory, getVehicle, setVehicle };
}
