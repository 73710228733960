<script setup>
import { useUserStore } from '@/stores/UserStore';
import { useRouter } from 'vue-router';
import { ref } from 'vue';

import QRCodeInput from '@/components/QRCodeInput.vue';

const userid = ref('');
const pin = ref('');

const errorText = ref('');

const router = useRouter();
const user = useUserStore();

function login() {
    user.login(userid.value, pin.value).then(onLogin).catch(onError);
}

function onLogin() {
    if (user.isAuthenticated()) {
        router.push('/');
    } else {
        errorText.value = 'Invalid userid or pin';
    }
}

function onError(error) {
    errorText.value = error;
}

function onUidEnter(id) {
    userid.value = id;
    pin.value = '';
    document.querySelector('input[type="password"]').focus();
}

function onPinEnter() {
    login();
}

function toggleFullscreen() {
    document.fullscreenElement ? document.exitFullscreen() : document.documentElement.requestFullscreen();
}
</script>

<template>
    <div class="wrapper" @dblclick="toggleFullscreen">
        <h1>
            <img src="img/logo-thw_min.svg" alt="Logo" />
            Fachzug Logistik
        </h1>
        <div>
            <h2 v-if="errorText">{{ errorText }}</h2>
            <QRCodeInput @input="onUidEnter" :hideInput="false" />
            <!-- <input type="text" placeholder="Userid" v-model="userid" v-on:keyup.enter="onUidEnter" /> -->
            <input type="password" placeholder="Pin" v-model="pin" v-on:keyup.enter="onPinEnter" />
            <button @click="login">Login</button>
        </div>
    </div>
    <div class="cover"></div>
</template>

<style lang="scss">
.qrcodeinput {
    padding: 0.5rem !important;
    font-size: 1rem !important;
    border: none;
    border-radius: 0.5rem !important;

    background-color: white;

    input {
        padding: 0 !important;
        font-size: 1em;
    }
}
</style>

<style lang="scss" scoped>
.cover {
    height: 4rem;
    position: absolute;
    background-color: #073497;

    bottom: 0;
    left: 0;
    right: 0;
}
.wrapper {
    background-color: #073497;
    width: 100%;
    height: calc(100vh + 4rem);

    color: white;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    box-sizing: border-box;

    margin-bottom: 0 !important;

    user-select: none;

    div {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
}

input {
    padding: 0.5rem;
    font-size: 1rem;
    border: none;
    border-radius: 0.5rem;
}

button {
    padding: 0.5rem 1rem;
    font-size: 1rem;
    border: none;
    border-radius: 0.5rem;
    background-color: #073497;
    color: white;
    cursor: pointer;
}

h1 {
    display: flex;
    align-items: center;
    gap: 1rem;
}

h2 {
    position: absolute;
    color: red;

    font-size: 1rem;
    margin: 0;

    top: -1.5rem;
}

img {
    width: 4rem;
    height: 4rem;
}
</style>
