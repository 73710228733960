<script setup>
import { useStatusStore } from '@/stores/StatusStore';
import { useUserStore } from '@/stores/UserStore';
import { useRouter } from 'vue-router';
import { ref, watch } from 'vue';

const user = useUserStore();

const router = useRouter();

const status = useStatusStore();

function logout() {
    user.logout();
    router.push('/login');
}

function toggleFullscreen() {
    document.fullscreenElement ? document.exitFullscreen() : document.documentElement.requestFullscreen();
}

function updateServiceWorker() {
    status.updatedServiceWorker.postMessage({ action: 'skipWaiting' });
}

const headerColor = ref('#073497');
watch(
    () => status.online,
    (online) => {
        headerColor.value = online ? '#073497' : '#4b6396';
    }
);
</script>

<template>
    <nav v-if="user.isAuthenticated()" @dblclick="toggleFullscreen">
        <h1><img src="img/logo-thw_min.svg" alt="Logo" />Fachzug Logistik</h1>
        <div class="link_elements">
            <!-- <router-link class="link_element" to="/">Home</router-link> | -->
            <!-- <router-link class="link_element" to="/about">About</router-link> -->
            {{ user?.name?.charAt(0).toUpperCase() + user?.name?.slice(1) }}
            <button v-if="status.updateAvailable" @click="updateServiceWorker">
                <i class="bi bi-arrow-clockwise"></i>
            </button>
            <button @click="logout"><i class="bi bi-box-arrow-right"></i></button>
        </div>
    </nav>
</template>

<style lang="scss" scoped>
nav {
    position: sticky;
    top: 0;
    height: 2rem;

    background-color: v-bind('headerColor');
    color: white;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    user-select: none;
}

.link_elements {
    display: flex;
    align-items: center;
}

.link_element {
    color: white;
    text-decoration: none;
    margin-right: 1rem;
}

button {
    margin-left: 1rem;
    background-color: transparent;
    border: none;
    color: white;
    font-size: 1.5rem;
    cursor: pointer;
}

h1 {
    display: flex;
    gap: 1rem;
    align-items: center;
}
</style>
