import axios from 'axios';
import { subscribeUser } from './user';
import vehicle from './vehicle';
import users from './user';
import operation from './operation';
import inventory from './inventory';
import products from './products';
import transfers from './transfers';

export const client = axios.create({
    baseURL: 'https://api.oaac.info',
    headers: {
        'Content-Type': 'application/json',
    },
});

export default {
    users: users,
    vehicles: vehicle,
    operations: operation,
    inventory: inventory,
    products: products,
    transfers: transfers,
};
