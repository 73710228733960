import { client } from './base';
import { useUserStore } from '@/stores/UserStore';

type Transfer = {
    type: 'transfer' | 'loan' | 'return';
    description: string;
    date: string;
    in_internal?: {
        department: string;
        name: string;
        date: string;
    };
    out_internal?: {
        department: string;
        name: string;
        date: string;
    };
    in_external?: {
        department: string;
        name: string;
        date: string;
    };
    out_external?: {
        department: string;
        name: string;
        date: string;
    };
};

export function getAllTransfers(): Promise<any> {
    const user = useUserStore();

    return client.get(`/transfers`, {
        headers: {
            Authorization: `Bearer ${user.token}`,
        },
    });
}

export function getTransfer(id: number): Promise<any> {
    const user = useUserStore();

    return client.get(`/transfers/${id}`, {
        headers: {
            Authorization: `Bearer ${user.token}`,
        },
    });
}

export function createTransfer(data: Transfer): Promise<any> {
    const user = useUserStore();

    return client.post('/transfers', data, {
        headers: {
            Authorization: `Bearer ${user.token}`,
        },
    });
}

export default {
    all: getAllTransfers,
    get: getTransfer,
    create: createTransfer,
};
