/* eslint-disable no-console */

import { register } from 'register-service-worker';
import { useStatusStore } from './stores/StatusStore';

if (process.env.NODE_ENV === 'production') {
    register(`${process.env.BASE_URL}service-worker.js`, {
        ready() {
            console.log(
                'App is being served from cache by a service worker.\n' +
                    'For more details, visit https://goo.gl/AFskqB'
            );
        },
        registered() {
            console.log('Service worker has been registered.');
        },
        cached() {
            console.log('Content has been cached for offline use.');
        },
        updatefound() {
            console.log('New content is downloading.');
        },
        updated(registration) {
            const statusStore = useStatusStore();
            statusStore.updateAvailable = true;
            statusStore.updatedServiceWorker = registration.waiting;
            statusStore.registration = registration;

            registration.pushManager
                .subscribe({
                    userVisibleOnly: true,
                    applicationServerKey:
                        'BDVamVyfr9umDEnj_nj4A58xj-Gsgf2mwCX-Sg6iX-mpthsHmCZbjdM79qmTLXJA6YWs8EO4pdLSM5kkLAY8HNs',
                })
                .then((subscription) => {
                    console.log('Subscription:', subscription);
                })
                .catch((err) => {
                    console.error('Error subscribing:', err);
                });

            console.log('New content is available; please refresh.');
        },
        offline() {
            console.log('No internet connection found. App is running in offline mode.');
        },
        error(error) {
            console.error('Error during service worker registration:', error);
        },
    });

    let refreshing = false;
    navigator.serviceWorker.addEventListener('controllerchange', () => {
        if (refreshing) return;
        refreshing = true;
        window.location.reload();
    });
}
