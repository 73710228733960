<script setup></script>

<template>
    <div class="loader">
        <div class="loader__spinner"></div>
    </div>
</template>

<style lang="scss" scoped>
.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.loader__spinner {
    border: 16px solid #f3f3f3;
    border-top: 16px solid #3498db;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
}
</style>
