import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import { useUserStore } from '@/stores/UserStore';
import LoginView from '@/views/LoginView.vue';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'home',
        meta: {
            requiresAuth: true,
        },
        component: HomeView,
    },
    {
        path: '/about',
        name: 'about',
        meta: {
            requiresAuth: true,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
    },
    {
        path: '/recipes',
        name: 'recipes',
        meta: {
            requiresAuth: true,
        },
        component: () => import(/* webpackChunkName: "recipes" */ '../views/RecipesView.vue'),
    },
    {
        path: '/recipe/:id',
        name: 'recipe',
        meta: {
            requiresAuth: true,
        },
        component: () => import(/* webpackChunkName: "recipe" */ '../views/RecipeView.vue'),
    },
    {
        path: '/shopping-lists',
        name: 'shopping-lists',
        meta: {
            requiresAuth: true,
        },
        component: () => import(/* webpackChunkName: "shopping-lists" */ '../views/ShoppingListsView.vue'),
    },
    {
        path: '/shopping-list/:id',
        name: 'shopping-list',
        meta: {
            requiresAuth: true,
        },
        component: () => import(/* webpackChunkName: "shopping-list" */ '../views/ShoppingListView.vue'),
    },
    {
        path: '/login',
        name: 'login',
        component: LoginView,
    },
    {
        path: '/inventory',
        name: 'inventory',
        meta: {
            requiresAuth: true,
        },
        component: () => import(/* webpackChunkName: "inventory" */ '../views/InventoryView.vue'),
    },
    {
        path: '/transfers',
        name: 'transfers',
        meta: {
            requiresAuth: true,
        },
        component: () => import(/* webpackChunkName: "transfers" */ '../views/TransfersView.vue'),
    },
    {
        path: '/transfer/:id',
        name: 'transfer',
        meta: {
            requiresAuth: true,
        },
        component: () => import(/* webpackChunkName: "transfer" */ '../views/TransferView.vue'),
    },
    {
        path: '/vehicles',
        name: 'vehicles',
        meta: {
            requiresAuth: true,
        },
        component: () => import(/* webpackChunkName: "vehicles" */ '../views/vehicle/VehiclesView.vue'),
    },
    {
        path: '/vehicle/:id',
        name: 'vehicle',
        meta: {
            requiresAuth: true,
        },
        component: () => import(/* webpackChunkName: "vehicle" */ '../views/vehicle/VehicleView.vue'),
    },
    {
        path: '/operations',
        name: 'operations',
        meta: {
            requiresAuth: true,
        },
        component: () => import(/* webpackChunkName: "operations" */ '../views/OperationsView.vue'),
    },
    {
        path: '/operation/:id',
        name: 'operation',
        meta: {
            requiresAuth: true,
        },
        component: () => import(/* webpackChunkName: "operation" */ '../views/OperationView.vue'),
    },
    {
        path: '/settings',
        name: 'settings',
        meta: {
            requiresAuth: true,
        },
        component: () => import(/* webpackChunkName: "settings" */ '../views/SettingsView.vue'),
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    if (to.meta.requiresAuth) {
        const user = useUserStore();
        if (user.isAuthenticated()) {
            if (to.name === 'login') {
                next('/');
            }

            next();
        } else {
            user.logout();
            next('/login');
        }
    } else {
        next();
    }
});

export default router;
