import { useUserStore } from '@/stores/UserStore';
import { client } from './base';

type User = {
    user_id: string;
    name: string;
    password: string;
};

export function subscribeUser(subscription: PushSubscription): Promise<any> {
    const user = useUserStore();

    return client.post(
        `/users/${user.userid}/subscribe`,
        { subscription },
        {
            headers: {
                Authorization: `Bearer ${user.token}`,
            },
        }
    );
}

export function createUser(data: User): Promise<any> {
    const user = useUserStore();

    return client.post('/users', data, {
        headers: {
            Authorization: `Bearer ${user.token}`,
        },
    });
}

export default {
    subscribe: subscribeUser,
    create: createUser,
};
