import { useUserStore } from '@/stores/UserStore';
import { client } from './base';
import { useApiStore } from '@/stores/ApiStore';

export function getAllInventoryItems(): Promise<any> {
    const user = useUserStore();

    const response = client.get(`/inventory?limit=-1`, {
        headers: {
            Authorization: `Bearer ${user.token}`,
        },
    });

    response.then((res) => {
        const apiStore = useApiStore();
        apiStore.inventory = res.data;
    });

    return response;
}

export function getInventoryItem(id: number): Promise<any> {
    const user = useUserStore();

    return client.get(`/inventory/${id}`, {
        headers: {
            Authorization: `Bearer ${user.token}`,
        },
    });
}

export function createInventoryItem(data: { name: string; code: string }): Promise<any> {
    const user = useUserStore();

    return client.post('/inventory', data, {
        headers: {
            Authorization: `Bearer ${user.token}`,
        },
    });
}

export function assignInventoryItemToVehicle(id: number, vehicleId: number): Promise<any> {
    const user = useUserStore();

    return client.post(
        `/inventory/${id}/location`,
        {
            location: 'vehicle',
            location_id: vehicleId,
        },
        {
            headers: {
                Authorization: `Bearer ${user.token}`,
            },
        }
    );
}

export function assignInventoryItemToOv(id: number): Promise<any> {
    const user = useUserStore();

    return client.post(
        `/inventory/${id}/location`,
        {
            location: 'ov',
            location_id: 1,
        },
        {
            headers: {
                Authorization: `Bearer ${user.token}`,
            },
        }
    );
}

export default {
    all: getAllInventoryItems,
    get: getInventoryItem,
    create: createInventoryItem,
    assign: {
        toVehicle: assignInventoryItemToVehicle,
        toOv: assignInventoryItemToOv,
    },
};
