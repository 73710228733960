<script setup>
import BaseLayout from '@/layouts/BaseLayout.vue';
import { onMounted, ref } from 'vue';
import api from '@/api/base.ts';

import { useApiStore } from '@/stores/ApiStore';

import LoadSpinner from '@/components/LoadSpinner.vue';
import DateDisplay from '@/components/DateDisplay.vue';

const apiStore = useApiStore();

const vehicles = ref(apiStore.vehicles);
const operations = ref([]);

onMounted(async () => {
    const v = await api.vehicles.all();
    vehicles.value = v.data;

    const o = await api.operations.all();
    operations.value = o.data;
});
</script>

<template>
    <BaseLayout title="Home">
        <div class="home">
            <div class="card">
                <h2>Einsätze</h2>
                <ul>
                    <li v-if="operations.length === 0">Aktuell keine Einsätze geplant</li>
                    <li
                        v-for="operation in operations"
                        :key="operation.id"
                        :class="
                            new Date(operation.date * 1000).getDate() === new Date().getDate() &&
                            new Date(operation.date * 1000).getMonth() === new Date().getMonth() &&
                            new Date(operation.date * 1000).getFullYear() === new Date().getFullYear()
                                ? 'today'
                                : operation.date * 1000 < Date.now()
                                ? 'past'
                                : 'future'
                        "
                    >
                        {{ operation.name }}
                        <DateDisplay :date="operation.date" />
                    </li>
                </ul>
            </div>
            <div class="card">
                <h2>Aktive Fahrzeuge</h2>
                <LoadSpinner v-if="vehicles.length === 0" />
                <ul class="vehicles">
                    <li v-if="vehicles.filter((v) => v.status !== 'standby').length === 0">
                        Alle Fahrzeuge einsatzbereit
                    </li>
                    <li v-for="vehicle in vehicles.filter((v) => v.status !== 'standby')" :key="vehicle.id">
                        {{ vehicle.name }} - {{ vehicle.callsign }}
                        <div v-if="vehicle.status == 'standby'">Einsatzbereit <i class="bi bi-house"> </i></div>
                        <div v-if="vehicle.status == 'workshop'">Werkstadt <i class="bi bi-tools"> </i></div>
                        <div v-if="vehicle.status == 'defective'">Defekt <i class="bi bi-ban"> </i></div>
                        <div v-if="vehicle.status == 'active'">Im Einsatz <i class="bi bi-truck"> </i></div>
                        <div v-if="vehicle.status == 'inuse'">Unterwegs <i class="bi bi-truck"> </i></div>
                    </li>
                </ul>
            </div>
        </div>
    </BaseLayout>
</template>

<style lang="scss" scoped>
.home {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;
}

.card {
    background-color: white;
    padding: 1rem;
    border-radius: 0.5rem;
    box-shadow: 0 0 1rem rgba(black, 0.1);

    width: 350px;
    flex-grow: 1;

    ul {
        padding: 0;
    }

    li {
        list-style: none;
        padding: 0.5rem;
        border-radius: 0.5rem;
        background-color: #f0f0f0;
        margin-bottom: 0.5rem;

        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.past {
    background-color: #f9f9f9 !important;
    color: #666;
}

.future {
    background-color: #f0f0f0 !important;
}

.today {
    background-color: #b9ffbb !important;
}
</style>
