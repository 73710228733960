import { client } from './base';
import { useUserStore } from '@/stores/UserStore';

export function searchProducts(query: string): Promise<any> {
    const user = useUserStore();

    return client.get(`/search?query=${query}`, {
        headers: {
            Authorization: `Bearer ${user.token}`,
        },
    });
}

export function getProduct(id: number): Promise<any> {
    const user = useUserStore();

    return client.get(`/products/${id}`, {
        headers: {
            Authorization: `Bearer ${user.token}`,
        },
    });
}

export function getProductArray(ids: number[]): Promise<any> {
    const user = useUserStore();

    return client.post('/products', ids, {
        headers: {
            Authorization: `Bearer ${user.token}`,
        },
    });
}

function get(data: Array<number> | number): Promise<any> {
    if (Array.isArray(data)) {
        return getProductArray(data);
    } else {
        return getProduct(data);
    }
}

export default {
    search: searchProducts,
    get,
};
