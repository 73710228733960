<script setup>
import { defineProps } from 'vue';

const props = defineProps({
    date: String,
});

import { ref } from 'vue';

const date = new Date(props.date * 1000);

const day = ref(date.getDate()).value.toString().padStart(2, '0');
const month = ref(date.getMonth() + 1)
    .value.toString()
    .padStart(2, '0');
const year = ref(date.getFullYear()).value.toString().padStart(4, '0');

const hour = ref(date.getHours()).value.toString().padStart(2, '0');
const minute = ref(date.getMinutes()).value.toString().padStart(2, '0');
</script>

<template>
    <time :datetime="date">{{ day }}.{{ month }}.{{ year }} {{ hour }}:{{ minute }}</time>
</template>

<style lang="scss" scoped></style>
