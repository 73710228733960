import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useStatusStore = defineStore('status', StatusStore);

function StatusStore() {
    const online = ref<boolean>(navigator.onLine);
    const updateAvailable = ref<boolean>(false);
    const updatedServiceWorker = ref<null | ServiceWorker>(null);
    const registration = ref<null | ServiceWorkerRegistration>(null);

    return { online, updateAvailable, updatedServiceWorker, registration };
}
