import { client } from './base';
import { useUserStore } from '@/stores/UserStore';
import { useApiStore } from '@/stores/ApiStore';

export type Vehicle = {
    id: number;
    name: string;
    plate: string;
    callsign: string;
    length: number;
    width: number;
    height: number;
    weight: number;
    weightlimit: number;
    image: string;
    status: string;
    note: string;
};

export function getAllVehicles(subscription: PushSubscription): Promise<any> {
    const user = useUserStore();

    const promise = client.get(`/vehicles`, {
        headers: {
            Authorization: `Bearer ${user.token}`,
        },
    });

    promise.then((response) => {
        const apiStore = useApiStore();
        apiStore.vehicles = response.data;
    });

    return promise;
}

export function getVehicle(id: number): Promise<any> {
    const user = useUserStore();

    return client.get(`/vehicles/${id}`, {
        headers: {
            Authorization: `Bearer ${user.token}`,
        },
    });
}

export function deleteVehicle(id: number): Promise<any> {
    const user = useUserStore();

    return client.delete(`/vehicles/${id}`, {
        headers: {
            Authorization: `Bearer ${user.token}`,
        },
    });
}

export function createVehicle(data: Vehicle): Promise<any> {
    const user = useUserStore();

    return client.post('/vehicles', data, {
        headers: {
            Authorization: `Bearer ${user.token}`,
        },
    });
}

export function updateVehicle(id: number, data: Partial<Vehicle>): Promise<any> {
    const user = useUserStore();

    return client.put(`/vehicles/${id}`, data, {
        headers: {
            Authorization: `Bearer ${user.token}`,
        },
    });
}

export function updateVehicleStatus(id: number, status: string, note: string): Promise<any> {
    const user = useUserStore();

    return client.put(
        `/vehicles/${id}/status`,
        { status, note },
        {
            headers: {
                Authorization: `Bearer ${user.token}`,
            },
        }
    );
}

export default {
    all: getAllVehicles,
    get: getVehicle,
    delete: deleteVehicle,
    create: createVehicle,
    update: updateVehicle,
    updateStatus: updateVehicleStatus,
};
