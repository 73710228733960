<script setup>
import { ref } from 'vue';
import { useUserStore } from '@/stores/UserStore';
import SideBarLink from '@/components/SideBarLink.vue';

const open = ref(true);
const user = useUserStore();
</script>

<template>
    <nav v-if="user.isAuthenticated()" class="sidebar" :class="{ open }">
        <div class="links">
            <div>
                <SideBarLink to="/" icon="bi-house-door" :collapsed="open">Home</SideBarLink>
                <!-- <SideBarLink to="/recipes" icon="bi-basket" :collapsed="open">Rezepte</SideBarLink> -->
                <SideBarLink to="/shopping-lists" icon="bi-cart" :collapsed="open">Einkaufsliste</SideBarLink>
                <SideBarLink to="/inventory" icon="bi-archive" :collapsed="open">Inventar</SideBarLink>
                <SideBarLink to="/transfers" icon="bi-arrow-left-right" :collapsed="open">Übergabe</SideBarLink>
                <SideBarLink to="/vehicles" icon="bi-truck" :collapsed="open">Fahrzeuge</SideBarLink>
                <SideBarLink to="/operations" icon="bi-crosshair" :collapsed="open">Einsätze</SideBarLink>
            </div>
            <div>
                <SideBarLink class="settings" to="/settings" icon="bi-gear" :collapsed="open">Settings</SideBarLink>
            </div>
        </div>
        <button @click="open = !open">
            <i :class="!open ? 'bi bi-chevron-double-left' : 'bi bi-chevron-double-right'"></i>
        </button>
    </nav>
</template>

<style lang="scss" scoped>
@media (orientation: portrait) {
    .sidebar {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: white;
        flex-direction: row !important;
        justify-content: center;

        border-top: 1px solid black;
    }

    .links {
        flex-direction: row !important;
        flex-grow: 1;

        div {
            display: flex;
            flex-direction: row;
        }
    }

    button {
        display: none;
    }
}

.sidebar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;

    padding: 0;

    border-right: 1px solid black;
}

.links {
    display: flex;
    flex-direction: column;

    flex-grow: 1;
    justify-content: space-between;
}

button {
    background-color: transparent;
    border: none;
    font-size: 1.5rem;
    padding: 1rem;
    cursor: pointer;
}

.sidebar.open {
    transform: translateX(0);
}
</style>
