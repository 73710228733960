import { client } from './base';
import { useUserStore } from '@/stores/UserStore';

type Operation = {
    name: string;
    description: string;
    date: string;
};

export function getOperations(): Promise<any> {
    const user = useUserStore();

    return client.get(`/operations`, {
        headers: {
            Authorization: `Bearer ${user.token}`,
        },
    });
}

export function createOperation(data: Operation): Promise<any> {
    const user = useUserStore();

    return client.post('/operations', data, {
        headers: {
            Authorization: `Bearer ${user.token}`,
        },
    });
}

export default {
    all: getOperations,
    create: createOperation,
};
