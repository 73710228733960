<script setup>
import { onMounted, ref, defineEmits, defineProps } from 'vue';
import { Html5Qrcode } from 'html5-qrcode';

const value = ref('');
const videoElement = ref(null);
const videoWrapper = ref(null);
const html5QrCode = ref(null);

const props = defineProps({
    clear: Boolean,
    hideInput: Boolean,
});

const mediaAllowed = ref(navigator.mediaDevices);

const emit = defineEmits(['input']);

onMounted(() => {
    html5QrCode.value = new Html5Qrcode('videoElement');
});

const openScanner = () => {
    videoWrapper.value.style.display = 'flex';
    const config = { fps: 10, qrbox: { width: 400, height: 400 } };

    // If you want to prefer front camera
    html5QrCode.value.start({ facingMode: 'environment' }, config, onScanSuccess);
};

const onScanSuccess = (decodedText) => {
    console.log(decodedText);
    value.value = decodedText;
    videoWrapper.value.style.display = 'none';
    html5QrCode.value.stop();
    sendInput();
};

const sendInput = () => {
    emit('input', value.value);
    if (props.clear) value.value = '';
};
</script>

<template>
    <div class="wrapper qrcodeinput">
        <input
            type="text"
            v-model="value"
            @change="() => sendInput()"
            placeholder="QR-Code"
            virtualKeyboardPolicy="manual"
            :class="{ hide: hideInput }"
        />
        <button v-if="mediaAllowed" @click="openScanner"><i class="bi bi-qr-code-scan"></i></button>
    </div>
    <div class="videoWrapper" ref="videoWrapper">
        <div ref="videoElement" id="videoElement" width="250"></div>
    </div>
</template>

<style scoped lang="scss">
.wrapper {
    display: inline-flex;
    flex-direction: row;
    align-items: center;

    height: 100%;
    border: 1px solid black;
    border-radius: 5px;

    padding: 0;

    &:focus-within {
        outline: 3px solid black;
    }

    input {
        flex-grow: 1;
        margin-bottom: 1rem;
        padding: 0.5em;
        margin: 0;
        border: none;
        background-color: transparent;
        height: 100%;

        &:focus {
            outline: none;
        }
    }

    input.hide {
        display: none;
    }

    button {
        margin: 0;
        display: inline-block;
        background: none;
        border: none;
        padding: 0;
        width: 2em;
        height: 100%;
        cursor: pointer;
        padding-right: 0.5em;
    }
}

.videoWrapper {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    align-items: center;
    justify-content: center;

    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;

    overflow: hidden;

    #videoElement {
        width: 100vw;
    }
}
</style>
