export class JWT {
    private _token: string;

    constructor(token: string) {
        this._token = token;
    }

    get data() {
        try {
            const jwtData = this._token.split('.')[1];
            const decodedData = atob(jwtData);
            return JSON.parse(decodedData);
        } catch (e) {
            return {};
        }
    }

    get isExpired() {
        const exp = this.data.exp * 1000;
        const now = new Date().getTime();
        console.log(exp, now, exp - now);
        return exp < now;
    }

    get isValid() {
        return !this.isExpired;
    }

    get header() {
        try {
            const jwtHeader = this._token.split('.')[0];
            const decodedHeader = atob(jwtHeader);
            return JSON.parse(decodedHeader);
        } catch (e) {
            return {};
        }
    }
}

export function displayDate(date: Date) {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString().padStart(4, '0');

    return `${day}.${month}.${year}`;
}
