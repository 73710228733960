<script setup>
import NavigationMenu from '@/components/NavigationMenu.vue';
import SideBar from '@/components/SideBar.vue';
import { onMounted, ref, watch } from 'vue';
import { useStatusStore } from '@/stores/StatusStore';
import { useUserStore } from '@/stores/UserStore';
import { useRouter } from 'vue-router';

const status = useStatusStore();
const user = useUserStore();
const router = useRouter();

if (!user.isAuthenticated()) {
    user.logout();
    router.push({ name: 'login' });
}

watch(
    () => user.isAuthenticated(),
    (isAuthenticated) => {
        if (!isAuthenticated) {
            router.push({ name: 'login' });
        }
    }
);

const updateDialog = ref();
onMounted(() => {
    const handleNetworkChange = () => {
        status.online = navigator.onLine;
    };

    window.addEventListener('online', handleNetworkChange);
    window.addEventListener('offline', handleNetworkChange);

    if (status.updateAvailable) {
        updateDialog.value.showModal();
    }
});

watch(
    () => status.updateAvailable,
    (updateAvailable) => {
        if (updateAvailable) {
            updateDialog.value.showModal();
        }
    }
);
</script>

<template>
    <NavigationMenu />
    <main>
        <SideBar />
        <router-view class="content" />
    </main>
    <dialog ref="updateDialog">
        <h2>Update verfügbar</h2>
        <p>Ein Update ist verfügbar. Klicken Sie auf den Button, um die Anwendung zu aktualisieren.</p>
        <button @click="status.updatedServiceWorker.postMessage({ action: 'skipWaiting' })">Aktualisieren</button>
    </dialog>
</template>

<style lang="scss">
body {
    margin: 0;
    font-family: Arial, sans-serif;
}

main {
    display: flex;
    flex-direction: row;

    height: calc(100vh - 4rem);
}

@media (orientation: portrait) {
    main {
        flex-direction: column;
    }

    .content {
        margin-bottom: 60px;
    }
}

.content {
    flex-grow: 1;
    padding: 1rem;

    overflow-y: auto;
}
</style>
