import { getToken } from '@/api/login';
import router from '@/router';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { useStatusStore } from './StatusStore';

import { JWT } from '@/util';

export const useUserStore = defineStore('user', UserStore);

function UserStore() {
    const token = ref(localStorage.getItem('token'));

    const jwt = new JWT(token.value || '');

    const id = ref(jwt.data.id);
    const userid = ref(jwt.data.userid);
    const name = ref(jwt.data.name);

    function isAuthenticated() {
        if (!token.value) return false;

        const jwt = new JWT(token.value);
        return jwt.isValid;
    }

    function login(uid: string, pin: string) {
        return new Promise((resolve, reject) => {
            getToken(uid, pin)
                .then((res) => {
                    const jwt = new JWT(res.data.token);
                    name.value = jwt.data.name;
                    userid.value = jwt.data.userid;
                    id.value = jwt.data.id;
                    token.value = res.data.token;
                    localStorage.setItem('token', res.data.token);

                    router.push('/');
                    resolve(true);
                })
                .catch((err) => {
                    if (err.response.status === 401) {
                        reject('Invalid userid or pin');
                    }
                    reject(JSON.stringify(err.response.data));
                });
        });
    }

    function logout() {
        localStorage.removeItem('token');
        token.value = null;
        userid.value = '';
    }

    function askPermission() {
        return new Promise(function (resolve, reject) {
            const permissionResult = Notification.requestPermission(function (result) {
                resolve(result);
            });

            if (permissionResult) {
                permissionResult.then(resolve, reject);
            }
        }).then(function (permissionResult) {
            if (permissionResult !== 'granted') {
                throw new Error("We weren't granted permission.");
            }
        });
    }

    return { token, id, userid, name, isAuthenticated, login, logout };
}
